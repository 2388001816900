.footer{
    display: flex;
    flex-direction: column;
    height: 400px;
    align-items: center;
    position: relative;
    justify-content: space-evenly;
}

.line_f{
    width: 100%;
    height: 3px;
    background-color: white;
    position: absolute;
    top: 0;
}

.socials{
    display: flex;
    gap: 5rem;
}
.socials img{
    width: 35px;
}

.logo_f{
    width: 200px;
}

.blur-footer{
    width: 20rem;
    height: 25rem;
    left: 15%;
    top: 25%;
}

.blur-footer-2{
    background-color: rgba(255, 0, 0, 0.397);
   

    width: 25rem;
    height: 25rem;
    right: 20%;
    top: 10%;
}