.joinUs {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
}

.left-j {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 3rem;
    color: white;
    text-transform: uppercase;
    font-style: italic;
    line-height: 70px;
    
}

.line {
    width: 40%;
    height: 5px;
    background-color: var(--orange);
    border-radius: 50%;
}


.right-j {
    flex: 1;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.right-j form{
    position: relative;
}
.emailContainer{
    width: 25rem;
    height: fit-content;
    background-color: gray;
    padding: 1em;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.emailContainer input {
    background-color: transparent;
    border: none;
    color: white;
    width: 70%;
}
.emailContainer input:hover{
    cursor: none;
}
.emailContainer input:focus {
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
}
.emailContainer input::placeholder {
    color: var(--lightgray);
}

.btn-j{
    background-color: var(--orange);
    color: white;
}

.Joined{
    color: white;
    margin: 0.25rem;  
    position: absolute; 

}



@media screen and (max-width:850px) {

    .joinUs{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }
    
}


@media screen and (max-width:450px) {

    .emailContainer{
        width: 16rem;

    }
    
}