.Testimonials {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3em;
}

.left-t {
    display: flex;
    flex-direction: column;
    color: white;
    gap: 1.5rem;
    padding: 1em 2em;
    flex: 1;
}

.left-t span:nth-of-type(1) {
    color: var(--orange);
    font-size: 1.3rem;
    font-weight: bold;
}

.left-t span {
    font-size: 3rem;
    font-weight: bold;
}

.author {
    font-size: 1rem;
    line-height: 25px;
    text-transform: none;
    letter-spacing: 1px;
    font-weight: normal;
}


.author span:nth-of-type(1) {
    font-size: 1rem;
    color: var(--orange);
}

.author span {
    font-size: 1rem;

}

/* right side */

.right-t {

    flex: 1;
    position: relative;

}

.author_img {
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
    z-index: 1;
}

.justBorder {
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 9rem;
    top: 1rem;
    border: 1px solid var(--orange);
}

.filled {
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 7rem;
    top: 3rem;
    background: var(--planCard);
}

.arrows {
    position: absolute;
    left: -20px;
    bottom: -30px;
}

.arrows img {
    width: 30px;
    margin-left: 1rem;
    cursor: pointer;
}

@media screen and (max-width:850px) {
    .Testimonials {
        flex-direction: column;
    }

    .right-t {

        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    .justBorder {
        display: none;
    }

    .filled {
        display: none;
    }
    .author_img{
        position: relative;
        top: 0;
        left: auto;
        right: auto;
    }
    .arrows{
        position: relative;
        left: auto;
        right: auto;
    }
}