.Programs {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}

.programs-header {
    display: flex;
    justify-content: center;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}


/* category */

.categories {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1em;
    color: white;
}

.categories .category {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 20%;
    height: 250px;
    background-color: var(--gray);
    padding: 1em;
    cursor: pointer;
}

.categories .category svg:nth-child(1) {
    width: 2.3rem;
    height: 2.3rem;
    fill: white;

}

.categories .category span:nth-of-type(1) {
    font-weight: bold;
}

.categories .category span:nth-of-type(2) {
    line-height: 25px;
    font-size: 0.9rem;
}

.categories .category:hover {
    background: var(--planCard);

}


.categories .category div {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.categories {
    flex-wrap: wrap;
}


@media screen and (max-width : 850px) {

    .programs-header {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        font-weight: bold;
        font-size: 1.5rem;
        text-transform: uppercase;
        font-style: italic;
    }

    .categories {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1em;
        flex-wrap: wrap;

    }
    
    .categories .category {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 80%;
        margin-bottom: 1rem;
        height: 250px;
        background-color: var(--gray);
        padding: 1em;
        cursor: pointer;
    }

}