.reasons {
    display: flex;
    padding: 1rem;
    gap: 2rem;

}

.left-r {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}

.left-r img {
    object-fit: cover;
}

.left-r img:nth-of-type(1) {
    width: 11rem;
    grid-row: 1/3;
    height: 25rem;
}

.left-r img:nth-of-type(2) {
    width: auto;
    height: 14rem;
    grid-column: 2/4;
}

.left-r img:nth-of-type(3) {
    width: 12rem;
    grid-column: 2/3;
    grid-row: 2;
}

.left-r img:nth-of-type(4) {
    width: 9rem;
    grid-row: 2;
    grid-column: 3/4;

}


.right-r {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.reasons {
    margin-bottom: 1em;
}

.right-r .reasonsTitle {
    font-size: 1.2rem;
    color: var(--orange);
    font-weight: 200;
}

.whyChooseUs {
    display: flex;
    gap: 1rem;
    font-weight: bold;
    font-size: 2.5rem;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}

.reasonsList {
    gap: 0.5rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
}

.reasonsList li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: start;

}

.reasonsList li img {
    width: 30px;
    margin-right: 1em;
}

.reasonsList li span {
    font-weight: bold;
    color: white;

}


.partners {
    text-transform: uppercase;
    color: var(--gray);
}


.logos {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.logos img {
    width: 35px;
}


@media screen and (max-width : 850px) {
    .reasons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .left-r {
        flex: 1;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        grid-auto-rows: 1fr;
    }

    .partners {
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 450px) {

    .left-r img:nth-of-type(4) {
            display: none;
    
    }

    .left-r {
        gap: 0.5rem;
    }
.left-r img:nth-of-type(1) {
    width: 7rem;
    grid-row: 1/3;
    height: 20rem;
}

.left-r img:nth-of-type(2) {
    width: auto;
    height: 9rem;
    grid-column: 2/4;
}

.left-r img:nth-of-type(3) {
    width: 12rem;
    grid-column: 2/3;
    grid-row: 2;
}
    
}